
import "./Home.less";
import { useQuery } from '@apollo/client';
import MarqueeComponent from "./home-components/Marquee";
import { GET_ANNOUNCEMENT, GET_BANNER } from "./Home.gql";

import banner1 from '../../../assets/images/banner1.jpg';
import banner2 from '../../../assets/images/banner2.jpg';
import banner3 from '../../../assets/images/banner3.jpg';
import banner4 from '../../../assets/images/banner4.jpg';
import FavoriteCarousels from "./home-components/FavoriteCarousels";
import Banners from "../../../shared/desktop/banners/Banners";
import { useEffect, useState } from "react";
import Announcement from "../../../shared/desktop/announcement/Announcement";
import { useIntl } from "react-intl";
import { useAuth } from "../../../hooks/auth/AuthContext";

const Home = () => {
    const { authState } = useAuth();
    const intl = useIntl();
    const [mainBanner, setMainBanner] = useState<any>([]);
    const [favBanner, setFavBanner] = useState<any>([]);
    const [favBanner1, setFavBanner1] = useState<any>([]);
    const { data: bannerData } = useQuery(GET_BANNER, {
        variables: { companyId: process.env.REACT_APP_COMPANY_ID ?? '' }, fetchPolicy: 'cache-and-network'
    });
    const [showAnnouncement, setShowAnnouncement] = useState(false);
    const { data } = useQuery(GET_ANNOUNCEMENT, { fetchPolicy: "no-cache" });
    const [marqueeData, setMarqueeData] = useState<any>();

    useEffect(() => {
        console.log('authState', authState)
        if (authState.isLogin && localStorage.getItem('sannouncement') === '1') {
            setShowAnnouncement(true);
        }
    }, [])

    useEffect(() => {
        if (authState.isLogin && localStorage.getItem('sannouncement') === '1') {
            setShowAnnouncement(true);
        }
    }, [authState.isLogin])

    useEffect(() => {
        if (bannerData && bannerData.banners) {
            setMainBanner(bannerData.banners.length > 0 ? bannerData.banners.filter((u: any) => u.type === 'MAIN') : []);
            setFavBanner(bannerData.banners.length > 0 ? bannerData.banners.filter((u: any) => u.type === 'FAVOURITE') : []);
            setFavBanner1(bannerData.banners.length > 0 ? bannerData.banners.filter((u: any) => u.type === 'FAVOURITE').sort((a: any, b: any) => { return b.title.localeCompare(a.title); }) : []);
        }    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [bannerData])

    useEffect(() => {
        if (data && data.announcement) {
            setMarqueeData(data.announcement)
        }    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data])

    // const marqueeData = {
    //     announcement: [{
    //         id: '1',
    //         message: {
    //             "bn": "Betlian এ যোগ দিন,আপনার বিশ্বস্ত ক্রিকেট ট্রেডিং প্ল্যাটফর্ম । এখনই আপনার ফ্রী আইডির জন্য সাইন আপ করুন ।   বিকাশ এবং উপায়ে ২৪ ঘণ্টা ডিপোজিট ।",
    //             "en": "Betlian এ যোগ দিন,আপনার বিশ্বস্ত ক্রিকেট ট্রেডিং প্ল্যাটফর্ম । এখনই আপনার ফ্রী আইডির জন্য সাইন আপ করুন ।   বিকাশ এবং উপায়ে ২৪ ঘণ্টা ডিপোজিট ।",
    //         },
    //     }]
    // }

    return (
        <>
            <div className="home-container">
                <Banners bannerData={mainBanner} />
                {marqueeData &&
                    <MarqueeComponent marqueeData={marqueeData} />
                }
                <FavoriteCarousels bannerData={favBanner} bannerData1={favBanner1} />
            </div>
            <Announcement showModal={showAnnouncement} setShowModal={setShowAnnouncement} title={intl.formatMessage({ id: 'ANNOUNCEMENT_TITLE' })}
                contents={[{ id: 1, content: intl.formatMessage({ id: 'ANNOUNCEMENT_CONTENT_1' }) },
                { id: 2, content: intl.formatMessage({ id: 'ANNOUNCEMENT_CONTENT_2' }) },
                { id: 3, content: intl.formatMessage({ id: 'ANNOUNCEMENT_CONTENT_3' }) },
                { id: 4, content: intl.formatMessage({ id: 'ANNOUNCEMENT_CONTENT_4' }) },
                { id: 5, content: intl.formatMessage({ id: 'ANNOUNCEMENT_CONTENT_5' }) }
                ]}
            />
        </>
    );
};

export default Home;
