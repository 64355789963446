import './App.less';
import './Maintenance.less';

const Maintenance = () => {

    return (
        <div className="site-maintenance-container">
            
        </div>
    );
}

export default Maintenance;