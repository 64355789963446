import providerExchange from '../../../../../assets/images/provider/icon-exchange-mobile.svg';
import providerSportsbook from '../../../../../assets/images/provider/icon-sportbook-mobile.svg';
import providerVirtual from '../../../../../assets/images/provider/icon-virtual-mobile.svg';
import providerKaddabi from '../../../../../assets/images/provider/icon-kabaddi-mobile.svg';
import providerVenus from '../../../../../assets/images/provider/provider-venus-mobile.png';
import providerPt from '../../../../../assets/images/provider/provider-pt-mobile.png';
import providerPp from '../../../../../assets/images/provider/provider-pp-mobile.png';
import providerEvo from '../../../../../assets/images/provider/provider-evo-mobile.png';
import providerAe from '../../../../../assets/images/provider/provider-sexy-mobile.png';
import providerEzu from '../../../../../assets/images/provider/provider-ezugi-mobile.png';
import providerCQ9 from '../../../../../assets/images/provider/provider-cq9-mobile.png';
import providerJili from '../../../../../assets/images/provider/provider-jili-mobile.png';
import providerFc from '../../../../../assets/images/provider/provider-fc-mobile.png';
import providerJdb from '../../../../../assets/images/provider/provider-jdb-mobile.png';
import providerKa from '../../../../../assets/images/provider/provider-ka-mobile.png';
import providerNumber from '../../../../../assets/images/provider/provider-number-mobile.png';
import providerPg from '../../../../../assets/images/provider/provider-pg-mobile.png';
import providerSg from '../../../../../assets/images/provider/provider-sg-mobile.png';
import providerNetent from '../../../../../assets/images/provider/provider-netent-mobile.png';
import providerRt from '../../../../../assets/images/provider/provider-rt-mobile.png';
import providerKm from '../../../../../assets/images/provider/provider-km-mobile.png';
import providerBpoker from '../../../../../assets/images/provider/provider-bpoker-mobile.png';
import providerLudo from '../../../../../assets/images/provider/provider-ludo-mobile.png';
import gameCasino from '../../../../../assets/images/game/icon-casino-mobile.svg';
import gameSlot from '../../../../../assets/images/game/icon-slot-mobile.svg';
import gameSport from '../../../../../assets/images/game/icon-sport-mobile.svg';
import gameFish from '../../../../../assets/images/game/icon-fish-mobile.svg';
import gameLottery from '../../../../../assets/images/game/icon-lottery-mobile.svg';
import gameTable from '../../../../../assets/images/game/icon-table-mobile.svg';

export interface Category {
    id: string;
    label: string;
    icon: string;
    imageUrl: string;
    provider: Provider[];
}

export interface Provider {
    id: string;
    label: string;
    imageUrl?: string;
    icon: string;
    gameUrl: string;
}

export const gameCategories: Category[] = [
    {
        'id': 'sports',
        'label': 'GAME_SPORTS',
        'icon': 'sports-icon',
        'imageUrl': gameSport,
        'provider': [
            {
                'id': 'exchange',
                'label': 'PROVIDER_SPORTS_EXCHANGE',
                'icon': 'exchange-icon',
                'gameUrl': '',
            },
            {
                'id': 'sportsbook',
                'label': 'PROVIDER_SPORTS_SPORTSBOOK',
                'icon': 'sportbook-icon',
                'gameUrl': '',
            },
            {
                'id': 'virtual',
                'label': 'PROVIDER_SPORTS_VIRTUAL',
                'icon': 'virtual-icon',
                'gameUrl': '',
            },
            {
                'id': 'KABADDI',
                'label': 'PROVIDER_SPORTS_KABADDI',
                'icon': 'kabaddi-icon',
                'gameUrl': '',
            },
        ]
    },
    {
        'id': 'casino',
        'label': 'GAME_CASINO',
        'icon': 'casino-icon',
        'imageUrl': gameCasino,
        'provider': [
            {
                'id': 'venus',
                'label': 'PROVIDER_CASINO_VENUS',
                'imageUrl': providerVenus,
                'icon': 'awcmvenus-icon',
                'gameUrl': '',
            },
            {
                'id': 'pt',
                'label': 'PROVIDER_CASINO_PT',
                'imageUrl': providerPt,
                'icon': 'pt-icon',
                'gameUrl': '',
            },
            {
                'id': 'pp',
                'label': 'PROVIDER_CASINO_PP',
                'imageUrl': providerPp,
                'icon': 'pp-icon',
                'gameUrl': '',
            },
            {
                'id': 'evo',
                'label': 'PROVIDER_CASINO_EVO',
                'imageUrl': providerEvo,
                'icon': 'evo-icon',
                'gameUrl': '',
            },
            {
                'id': 'ae',
                'label': 'PROVIDER_CASINO_AE',
                'imageUrl': providerAe,
                'icon': 'ae-icon',
                'gameUrl': '',
            },
            {
                'id': 'ezugi',
                'label': 'PROVIDER_CASINO_EZU',
                'imageUrl': providerEzu,
                'icon': 'ezugi-icon',
                'gameUrl': '',
            },
        ]
    },
    {
        'id': 'fishing',
        'label': 'GAME_FISHING',
        'icon': 'fishing-icon',
        'imageUrl': gameFish,
        'provider': [
            {
                'id': 'cq9',
                'label': 'PROVIDER_FISH_CQ9',
                'imageUrl': providerCQ9,
                'icon': 'cq9-icon',
                'gameUrl': '',
            },
            {
                'id': 'jili',
                'label': 'PROVIDER_FISH_JILI',
                'imageUrl': providerJili,
                'icon': 'jili-icon',
                'gameUrl': '',
            },
            {
                'id': 'fc',
                'label': 'PROVIDER_FISH_FC',
                'imageUrl': providerFc,
                'icon': 'fc-icon',
                'gameUrl': '',
            },
            {
                'id': 'jdb',
                'label': 'PROVIDER_FISH_JDB',
                'imageUrl': providerJdb,
                'icon': 'jdb-icon',
                'gameUrl': '',
            },
            {
                'id': 'ka',
                'label': 'PROVIDER_FISH_KA',
                'imageUrl': providerKa,
                'icon': 'ka-icon',
                'gameUrl': '',
            },
        ]
    },
    {
        'id': 'lottery',
        'label': 'GAME_LOTTERY',
        'icon': 'lottery-icon',
        'imageUrl': gameLottery,
        'provider': [
            {
                'id': 'number',
                'label': 'PROVIDER_LOTTERY_NUMBER',
                'imageUrl': providerNumber,
                'icon': 'number-icon',
                'gameUrl': '',
            },
        ]
    },
    {
        'id': 'slot',
        'label': 'GAME_SLOT',
        'icon': 'slot-icon',
        'imageUrl': gameSlot,
        'provider': [
            {
                'id': 'cq9',
                'label': 'PROVIDER_SLOT_CQ9',
                'imageUrl': providerCQ9,
                'icon': 'cq9-icon',
                'gameUrl': '',
            },
            {
                'id': 'jili',
                'label': 'PROVIDER_SLOT_JILI',
                'imageUrl': providerJili,
                'icon': 'jili-icon',
                'gameUrl': '',
            },
            {
                'id': 'jdb',
                'label': 'PROVIDER_SLOT_JDB',
                'imageUrl': providerJdb,
                'icon': 'jdb-icon',
                'gameUrl': '',
            },
            {
                'id': 'pg',
                'label': 'PROVIDER_SLOT_PG',
                'imageUrl': providerPg,
                'icon': 'pg-icon',
                'gameUrl': '',
            },
            {
                'id': 'sg',
                'label': 'PROVIDER_SLOT_SG',
                'imageUrl': providerSg,
                'icon': 'sg-icon',
                'gameUrl': '',
            },
            {
                'id': 'pt',
                'label': 'PROVIDER_SLOT_PT',
                'imageUrl': providerPt,
                'icon': 'pt-icon',
                'gameUrl': '',
            },
            {
                'id': 'fc',
                'label': 'PROVIDER_SLOT_FC',
                'imageUrl': providerFc,
                'icon': 'fc-icon',
                'gameUrl': '',
            },
            {
                'id': 'pp',
                'label': 'PROVIDER_SLOT_PP',
                'imageUrl': providerPp,
                'icon': 'pp-icon',
                'gameUrl': '',
            },
            {
                'id': 'ka',
                'label': 'PROVIDER_SLOT_KA',
                'imageUrl': providerKa,
                'icon': 'ka-icon',
                'gameUrl': '',
            },
            {
                'id': 'netent',
                'label': 'PROVIDER_SLOT_NETENT',
                'imageUrl': providerNetent,
                'icon': 'rt-icon',
                'gameUrl': '',
            },
            {
                'id': 'rt',
                'label': 'PROVIDER_SLOT_RT',
                'imageUrl': providerRt,
                'icon': 'rt-icon',
                'gameUrl': '',
            },
        ]
    },
    {
        'id': 'table',
        'label': 'GAME_TABLE',
        'icon': 'table-icon',
        'imageUrl': gameTable,
        'provider': [
            {
                'id': 'km',
                'label': 'PROVIDER_TABLE_KM',
                'imageUrl': providerKm,
                'icon': 'km-icon',
                'gameUrl': '',
            },
            {
                'id': 'jili',
                'label': 'PROVIDER_TABLE_JILI',
                'imageUrl': providerJili,
                'icon': 'jili-icon',
                'gameUrl': '',
            },
            {
                'id': 'ka',
                'label': 'PROVIDER_TABLE_KA',
                'imageUrl': providerKa,
                'icon': 'ka-icon',
                'gameUrl': '',
            },
            {
                'id': 'cq9',
                'label': 'PROVIDER_TABLE_CQ9',
                'imageUrl': providerCQ9,
                'icon': 'cq9-icon',
                'gameUrl': '',
            },
            {
                'id': 'bpoker',
                'label': 'PROVIDER_TABLE_BPOKER',
                'imageUrl': providerBpoker,
                'icon': 'bpoker-icon',
                'gameUrl': '',
            },
            {
                'id': 'ludo',
                'label': 'PROVIDER_TABLE_LUDO',
                'imageUrl': providerLudo,
                'icon': 'ludo-icon',
                'gameUrl': '',
            },
        ]
    },
];