import { Modal } from 'antd';
import React from 'react';
import './Announcement.less';

interface Props {
    showModal: boolean;
    title: string;
    contents: any;
    setShowModal: React.Dispatch<React.SetStateAction<boolean>>;
    cancelText?: string;
    actionText?: string;
}

const Announcement = ({ showModal, title, contents, setShowModal }: Props) => {

    const closeAnnouncement = () => {
        setShowModal(false);
        localStorage.setItem('sannouncement', '0');
    }

    return (
        <Modal
            className='announcement-modal'
            visible={showModal}
            closable={true}
            title={title}
            footer={null}
            onCancel={closeAnnouncement}
            destroyOnClose
        >
            {contents.map((content: any) => {
                return (
                    <div key={content.id} className='announcement-content'>{content.content}</div>
                );
            })}
        </Modal>
    )
}

export default Announcement;