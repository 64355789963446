
import { Button } from 'antd';
import { FormattedMessage } from 'react-intl';
import './PrimaryButtonMobile.less';

interface Props {
    btnText: string;
    isFormBtn: boolean;
    onClick: any;
    additionalClassName?: string
    loading?: boolean;
    disabled?: boolean;
}

const PrimaryButtonMobile = ({ disabled=false, btnText, isFormBtn, onClick, additionalClassName = "m-primary-btn", loading }: Props) => {
    return (
        <>
            <Button block disabled={disabled} type="primary" htmlType={isFormBtn ? "submit" : "button"} className={additionalClassName} onClick={onClick} loading={loading}>
                <FormattedMessage id={btnText} />
            </Button>
        </>
    )
}

export default PrimaryButtonMobile;