
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Pagination, Navigation, Autoplay } from "swiper";
import { Spin } from "antd";
import { useLayout } from "../../../../../hooks/layout/LayoutContext";
import { setDBLanguageToGet } from "../../../../../shared/helpers/language.helpers";
// import { setDBLanguageToGet } from "../../../../shared/helpers/language.helpers";
// import { useLayout } from "../../../../hooks/layout/LayoutContext";

SwiperCore.use([Autoplay]);

interface Props {
    bannerData: any;
}

const BannerMobile = ({ bannerData }: Props) => {
    const { layoutState } = useLayout();

    return (
        <div className="m-home-banner">
            {bannerData && bannerData.length > 0 ?
                <Swiper
                    autoplay={true}
                    grabCursor={true}
                    slidesPerView={1}
                    centeredSlides={true}
                    loop={true}
                    loopFillGroupWithBlank={true}
                    pagination={{
                        clickable: true,
                    }}
                    navigation={false}
                    modules={[Pagination, Navigation]}
                    className="mySwiper"
                >
                    {bannerData && bannerData.map((banner: any) => {
                        return (
                            <SwiperSlide key={banner.id}>
                                <div key={banner.id}>
                                    <img alt={banner.title} className="carousel-image"
                                        src={JSON.parse(banner?.pictureUrl)[setDBLanguageToGet(layoutState.locale)]}
                                    />
                                </div>
                            </SwiperSlide>

                        );
                    })}
                </Swiper>
                :
                <Spin />
            }
        </div>
    )
}

export default BannerMobile;