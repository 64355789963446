
import "../Auth.less";
import { Alert, Button, Checkbox, Form, Input, Select } from "antd";
import { FormattedMessage, useIntl } from 'react-intl';
import nextArrow from '../../../../assets/images/register/next-arrow.png';
import { useLocation, useNavigate } from "react-router-dom";
import { encode } from "base-64";
import { CHECK_USERNAME } from "../auth.gql";
import { useMutation } from "@apollo/client";
import useSpinner from "../../../../hooks/layout/useSpinner";
import { useEffect, useState } from "react";

interface Props {
    form: any;
    setCurrentStep: any;
    currentStep: any;
    affReferralInput: any;
    setAffReferralInput: any;
    isFromQueryString: boolean;
}

const RegisterStep1 = ({ form, setCurrentStep, currentStep, affReferralInput, setAffReferralInput, isFromQueryString }: Props) => {
    const intl = useIntl();
    const navigate = useNavigate();
    const { setLoading } = useSpinner();
    const [checkUsername, { data: data, loading: loading }] = useMutation(CHECK_USERNAME, { fetchPolicy: 'no-cache' });
    const [error, setError] = useState<string>();

    const currencyList = [
        {
            id: 'BDT',
            label: 'BDT'
        },
        // {
        //     id: 'INR',
        //     label: 'INR'
        // },
        // {
        //     id: 'PKR',
        //     label: 'PKR'
        // },
    ]

    useEffect(() => {
        if (loading) {
            setLoading(true);
        } else {
            setLoading(false);
        }
    }, [loading])

    useEffect(() => {
        if (data && data.checkUsername.status === 'SUCCESS') {
            if (!data.checkUsername.isExist) {
                const registerParam = {
                    username: form.getFieldValue('reg_username'),
                    password: encode(form.getFieldValue('reg_password')),
                    affiliate: form.getFieldValue('reg_referral') ?? ''
                };

                localStorage.setItem("regParam", JSON.stringify(registerParam));

                let nextStep = currentStep + 1;
                setCurrentStep(nextStep);
            } else {
                setError(intl.formatMessage({ id: 'ERROR_USER_EXISTS' }))
            }
        } else if (data) {
            setError(intl.formatMessage({ id: `ERROR_${data?.checkUsername?.status}` }));
        }// eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data]);

    const onFinish = (fieldsValue: any) => {
        checkUsername({
            variables: {
                username: fieldsValue.reg_username,
                referralId: affReferralInput,
                companyId: process.env.REACT_APP_COMPANY_ID ?? '',
            }
        });
    }

    return (
        <Form
            form={form}
            layout="vertical"
            initialValues={{ reg_currency: 'BDT', reg_referral: affReferralInput ?? undefined }}
            onFinish={onFinish}
        >
            {error && <Alert
                className="auth-error"
                message={<FormattedMessage id={error} />}
                type="error"
                showIcon
            />}
            <div className='reg-form-content-container'>
                <Form.Item
                    label={intl.formatMessage({ id: 'REGISTER_USERNAME' })}
                    name='reg_username'
                    rules={[
                        { required: true, message: intl.formatMessage({ id: 'LOGIN_USERNAME_REQUIRED' }) },
                        { min: 5, message: intl.formatMessage({ id: 'AUTH_INVALID_CODE' }) }
                    ]}
                >
                    <Input placeholder={intl.formatMessage({ id: 'REGISTER_USERNAME_PLACEHOLDER' })} />
                </Form.Item>
            </div>
            <div className='reg-form-content-container'>
                <Form.Item
                    label={intl.formatMessage({ id: 'REGISTER_PASSWORD' })}
                    name='reg_password'
                    rules={[
                        { required: true, message: intl.formatMessage({ id: 'PASSWORD_REQUIRED' }) },
                        {
                            pattern: /[A-Za-z0-9]{6,19}$/,
                            message: intl.formatMessage({ id: 'AUTH_REGISTER_FORM_PASSWORD_INVALID' })
                        }
                    ]}
                >
                    <Input.Password placeholder={intl.formatMessage({ id: 'REGISTER_PASSWORD_PLACEHOLDER' })} />
                </Form.Item>
            </div>
            <div className='reg-form-content-container'>
                <Form.Item
                    label={intl.formatMessage({ id: 'REGISTER_CONFIRM_PASSWORD' })}
                    name='reg_confirm_password'
                    dependencies={['reg_password']}
                    rules={[{ required: true, message: intl.formatMessage({ id: 'AUTH_REGISTER_FORM_PASSWORD_REQUIRED' }) },
                    ({ getFieldValue }) => ({
                        validator(_, value) {
                            if (!value || getFieldValue('reg_password') === value) {
                                return Promise.resolve();
                            }
                            return Promise.reject(new Error(intl.formatMessage({ id: 'AUTH_REGISTER_FORM_PASSWORD_NOT_MATCHED' })));
                        },
                    })]}>
                    <Input.Password placeholder={intl.formatMessage({ id: 'REGISTER_CONFIRM_PASSWORD_PLACEHOLDER' })} />
                </Form.Item>
            </div>
            <div className='reg-form-content-container'>
                <Form.Item
                    label={intl.formatMessage({ id: 'REGISTER_CURRENCY' })}
                    name='reg_currency'
                    rules={[
                        { required: true, message: intl.formatMessage({ id: 'PASSWORD_REQUIRED' }) },
                    ]}>
                    <Select
                        className="currency-dropdown"
                        dropdownClassName="currency-dropdown-list"
                        placeholder={intl.formatMessage({ id: 'REGISTER_CURRENCY_PLACEHOLDER' })}
                    //onChange={onChannelChange}
                    >
                        {currencyList?.map((val: any) => {
                            return (
                                <Select.Option key={val.id} value={val.id}>
                                    <FormattedMessage id={val.label} />
                                </Select.Option>
                            );
                        })}
                    </Select>
                </Form.Item>
            </div>
            <div className='reg-form-content-container'>
                <Form.Item
                    label={intl.formatMessage({ id: 'REGISTER_REFERRAL' })}
                    name='reg_referral'
                    rules={[
                        { min: 6, message: intl.formatMessage({ id: 'AUTH_INVALID_REFERRAL' }) }
                    ]}
                >
                    <Input placeholder={intl.formatMessage({ id: 'REGISTER_REFERRAL_PLACEHOLDER' })}
                        disabled={isFromQueryString}
                        onChange={e => setAffReferralInput(e.target.value)}
                        value={affReferralInput} />
                </Form.Item>
            </div>
            <div className='reg-form-button-container'>
                <Button className="reg-submit-btn" htmlType="submit">
                    <img src={nextArrow} alt='na' />
                </Button>
            </div>
        </Form>
    );
};

export default RegisterStep1;


