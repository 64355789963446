
import { Layout } from 'antd';
import '../GeneralFooter.less';

import safe1Icon from '../../../../assets/images/footer/safe_1.png';
import safe2Icon from '../../../../assets/images/footer/safe_2.png';
import safe3Icon from '../../../../assets/images/footer/safe_3.png';

import payment1Icon from '../../../../assets/images/footer/payment_1.png';
import payment2Icon from '../../../../assets/images/footer/payment_2.png';
import payment3Icon from '../../../../assets/images/footer/payment_3.png';
import payment4Icon from '../../../../assets/images/footer/payment_4.png';
import payment5Icon from '../../../../assets/images/footer/payment_5.png';
import payment6Icon from '../../../../assets/images/footer/payment_6.png';
import payment7Icon from '../../../../assets/images/footer/payment_7.png';
import payment8Icon from '../../../../assets/images/footer/payment_8.png';
import payment9Icon from '../../../../assets/images/footer/payment_9.png';

import { FormattedMessage } from 'react-intl';

const FooterSecondRow = () => {
    return (
        <div className='second-row-container'>
            <div className='content-container'>
                <div className='title-txt'><FormattedMessage id='FOOTER_RESPONSIBLE'/></div>
                <div className='icon-container'>
                    <img alt={'safe1'} className='safe-icon' src={safe1Icon} onClick={()=>{window.open('https://www.gamcare.org.uk/', 'window')}}/>
                    <img alt={'safe2'} className='safe-icon' src={safe2Icon} onClick={()=>{window.open('https://www.betlian.live/account/18-policy/', 'window')}}/>
                    <img alt={'safe3'} className='safe-icon' src={safe3Icon} onClick={()=>{window.open('https://www.betlian.live/responsible-gaming/', 'window')}}/>
                </div>
            </div>
            <div className='content-container'>
                <div className='title-txt'><FormattedMessage id='FOOTER_PAYMENT'/></div>
                <div className='payment-icon-container'>
                    <img alt={'payment'} className='payment-icon' src={payment1Icon}/>
                    <img alt={'payment'} className='payment-icon' src={payment2Icon}/>
                    <img alt={'payment'} className='payment-icon' src={payment3Icon}/>
                    <img alt={'payment'} className='payment-icon' src={payment4Icon}/>
                    <img alt={'payment'} className='payment-icon' src={payment5Icon}/>
                    {/* <img alt={'payment'} className='payment-icon' src={payment6Icon}/> */}
                    {/* <img alt={'payment'} className='payment-icon' src={payment7Icon}/> */}
                    {/* <img alt={'payment'} className='payment-icon' src={payment8Icon}/> */}
                    <img alt={'payment'} className='payment-icon' src={payment9Icon}/>
                </div>
            </div>
        </div>
    )
}

export default FooterSecondRow;