import './Phone-Input.less';
import { useQuery } from "@apollo/client";
import { Input, Select } from "antd";
import { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { GET_SIGNUP_COUNTRIES } from "../../gql/countries.gql";
const { Option } = Select;

interface Props {
    value?: string;
    onChange?: (value: string) => void;
    selectedCountryId?: string;
    selectedDialCode?: string;
    selectCountryId?: (countryCode: string) => void;
    disabled?: boolean;
}

const PhoneInput = ({ value, onChange, selectCountryId, selectedCountryId = 'BD', selectedDialCode = '+880', disabled = false }: Props) => {
    const { data } = useQuery(GET_SIGNUP_COUNTRIES);
    const { formatMessage } = useIntl();
    const [topCountries, setTopCountries] = useState([]);
    const [countries, setCountries] = useState([]);
    const [countryCode, setCountryCode] = useState(selectedDialCode);
    const [countryId, setCountryId] = useState<string>('BD');
    const intl = useIntl();
    const urlAccName = process.env.REACT_APP_STORAGE_ACCOUNTNAME ? process.env.REACT_APP_STORAGE_ACCOUNTNAME : '';

    useEffect(() => {
        if (data) {
            const modifiedCountries = data.signUpCountries.map((val: any) => {
                if (val.id === "CN" || val.id === "MY" || val.id === "TW" || val.id === "TH" || val.id === "ID") {
                    return { ...val, favourite: true }
                } else
                    return { ...val, favourite: false }
            });
            const filteredTopCountry = modifiedCountries.filter((val: any) => val.favourite);
            const filteredCountry = modifiedCountries.filter((val: any) => val.favourite === false);
            setTopCountries(filteredTopCountry)
            setCountries(filteredCountry);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data]);

    useEffect(() => {
        setCountryId(selectedCountryId ?? 'TH');
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedCountryId]);

    useEffect(() => {
        setCountryCode(selectedDialCode);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedDialCode]);

    const onPhoneNumberChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (onChange) {
            const { value } = e.target;
            const reg = /^\d*(\d*)?$/;
            if ((!isNaN(+value) && reg.test(value)) || value === '') {
                onChange(countryCode + value.replace(/0*(\d+)/, '$1'));
            } else {
                onChange(countryCode)
            }
        }
    };

    const onCountryCodeChange = (selectedValue: string, option: any) => {
        if (onChange) {
            onChange(option.title + (value?.replace(countryCode, '') || ''));
            setCountryCode(option.title);
            setCountryId(selectedValue);
        }
        if (selectCountryId) {
            selectCountryId(option.value);
        }
    }

    const selectCountries = (
        <Select disabled={disabled} showSearch className="country-selector" dropdownClassName="country-dropdown"
            defaultValue={selectedCountryId} optionFilterProp="children" onChange={onCountryCodeChange}
            value={countryId}
            filterOption={(input, option) =>
                option?.children[1].toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
            filterSort={(optionA, optionB) =>
                optionA.children[1].toLowerCase().localeCompare(optionB.children[1].toLowerCase())
            }
            optionLabelProp="displaytitle">
            {
                topCountries.map((d: any) => {
                    return (<Option key={d.id} value={d.id} title={`+${d.dialCode}`} displaytitle={
                        <div className="country-selected-title">
                            <img src={`https://${urlAccName}.blob.core.windows.net/flags/${d.id}.svg`} alt={d.id} />
                            {`+${d.dialCode}`}
                        </div>}
                        className="country-dropdown-item">
                        <img src={`https://${urlAccName}.blob.core.windows.net/flags/${d.id}.svg`} alt={d.id} />
                        {`${formatMessage({ id: 'COUNTRIES_' + d.id })} (+${d.dialCode})`}
                    </Option>)
                })
            }
            {
                countries.map((d: any) => {
                    return (<Option key={d.id} value={d.id} title={`+${d.dialCode}`} displaytitle={
                        <div className="country-selected-title">
                            <img src={`https://${urlAccName}.blob.core.windows.net/flags/${d.id}.svg`} alt={d.id} />
                            {`+${d.dialCode}`}
                        </div>}
                        className="country-dropdown-item">
                        <img src={`https://${urlAccName}.blob.core.windows.net/flags/${d.id}.svg`} alt={d.id} />
                        {`${formatMessage({ id: 'COUNTRIES_' + d.id })} (+${d.dialCode})`}
                    </Option>)
                })
            }
        </Select>
    );

    return (<Input addonBefore={selectCountries} maxLength={20} placeholder={intl.formatMessage({ id: 'REGISTER_PHONE_PLACEHOLDER' })}
        onChange={onPhoneNumberChange} value={value?.replace(countryCode, '')} disabled={disabled} />);
}

export default PhoneInput;