import React, { createContext, useContext, useReducer } from "react";

interface AuthState {
    isLogin: boolean;
    loginStatus: any;
    requiredPasswordChange: boolean;
    unreadNotification: boolean;
    hasSponsor: boolean;
    wallets?: any,
    walletCategory: any,
    bonusWalletType: any,
    totalBalance: number,
    maintenanceList: any,
    userProfile: null | {
        id: string,
        name: string,
        userName: string,
        memberId?: number,
        photoUrl?: string,
        countryId?: string,
        phone?: string,
        phoneVerified?: boolean,
        email?: boolean,
        emailVerified?: boolean,
        gender?: string,
        kycStatus?: string,
        kycApproved?: boolean,
        pinStatus?: boolean,
        bankAccountStatus?: boolean,
        isActive?: boolean,
        rank?: string,
        requiredPasswordChange: boolean,
        mt4Wallet?: any,
        verifiedForTransaction: boolean,
        hasSponsor: boolean,
        userRank: string,
        hasSyncThirdParty: boolean,
        firstSelfTradeAccountId: string,
        totalBalance: number,
        currency: string,
        referralCode: string,
        siteUrl: string,
        dobUpdated: boolean,
        registerTime?: Date,
        lastWithdrawal?: Date,
        lastDeposit?: Date,
        currentVipPoint: number,
        prevVipRankPoint: number,
        nextVipRankPoint: number,
        currentVipRankName: string,
        nextVipRankName: string,
        logoUrl: string,
        pointExchangeRate: number,
    }
}

const INITIAL_STATE: AuthState = {
    isLogin: false,
    loginStatus: null,
    requiredPasswordChange: false,
    unreadNotification: false,
    userProfile: null,
    hasSponsor: false,
    walletCategory: null,
    wallets: null,
    totalBalance: 0,
    bonusWalletType: null,
    maintenanceList: []
};

export const SET_LOGIN_STATUS = "[AUTH] SET_LOGIN_STATUS";
export const SET_LOGIN_ERROR = "[AUTH] SET_LOGIN_ERROR";
export const SET_CURRENT_USER = "[AUTH] SET_CURRENT_USER";
export const CLEAR_CURRENT_USER = "[AUTH] CLEAR_CURRENT_USER";
export const UPDATE_CURRENT_USER_PROFILE = "[AUTH] UPDATE_CURRENT_USER_PROFILE";
export const UPDATE_CHANGE_PASSWORD_STATUS = "[AUTH] UPDATE_CHANGE_PASSWORD_STATUS";
export const SET_SPONSOR = "[AUTH] SET_SPONSOR";
export const SET_USER_WALLET = "[AUTH] SET_USER_WALLET";
export const SET_MAINTENANCE_LIST = "[AUTH] SET_MAINTENANCE_LIST";
export const SET_LATEST_BALANCE = "[AUTH] SET_LATEST_BALANCE";

const reducer = (state: AuthState, action: { type: string, payload: any }): AuthState => {
    const { type, payload } = action;
    switch (type) {
        case SET_CURRENT_USER:
            return {
                ...state,
                userProfile: { ...payload['userProfile'] },
                requiredPasswordChange: payload['userProfile']?.requiredPasswordChange,
                unreadNotification: payload['userProfile']?.unreadNotification,
            }
        case UPDATE_CURRENT_USER_PROFILE:
            return {
                ...state,
                userProfile: { ...payload['userProfile'] }
            }
        case UPDATE_CHANGE_PASSWORD_STATUS:
            return { ...state, requiredPasswordChange: payload };
        case SET_LOGIN_STATUS:
            return { ...state, isLogin: payload };
        case SET_SPONSOR:
            return { ...state, hasSponsor: payload };
        case CLEAR_CURRENT_USER:
            return INITIAL_STATE;
        case SET_USER_WALLET:
            return { ...state, wallets: payload['wallets'], walletCategory: payload['walletCategory'], totalBalance: +payload['totalBalance'] }
        case SET_MAINTENANCE_LIST:
            return { ...state, maintenanceList: payload };
        case SET_LATEST_BALANCE:
            return { ...state, totalBalance: +payload, };
        default:
            return state;
    }
};

const authContext = createContext<{ authState: AuthState; authDispatch: React.Dispatch<any>; }>
    ({ authState: INITIAL_STATE, authDispatch: () => null });

export const AuthProvider = ({ children }: any) => {
    const [authState, authDispatch] = useReducer(reducer, INITIAL_STATE);
    return (
        <authContext.Provider value={{ authState, authDispatch }} >
            {children}
        </authContext.Provider>
    );
}

export const useAuth = () => {
    return useContext(authContext);
}