
import { Divider, Layout } from 'antd';
import { useEffect, useState } from 'react';
import '../../GeneralHeader.less';

import whatsAppIcon from '../../../../../assets/images/header/social_media_whatsapp.png';
import emailIcon from '../../../../../assets/images/header/social_media_email.png';
import fbIcon from '../../../../../assets/images/header/social_media_fb.png';
import telegramIcon from '../../../../../assets/images/header/social_media_telegram.png';
import { FormattedMessage } from 'react-intl';
import { forEach } from 'lodash';
import { useLayout } from '../../../../../hooks/layout/LayoutContext';
import { languageList } from '../../../modal/LanguageModal/LanguageList';
import LanguageModal from '../../../modal/LanguageModal/LanguageModal';
import moment from 'moment';
interface Props {
    telegramLink: string;
    fbLink: string;
    csEmail: string;
    whatsAppLink: string;
}

const HeaderFirstRow = ({telegramLink, fbLink, csEmail, whatsAppLink}: Props) => {
    const { layoutState, layoutDispatch } = useLayout();
    const [liveDate, setLiveDate] = useState<string>();
    const [languageModalVisible, setLanguageModalVisible] = useState(false);
    const [localLangList, setLocalLangList] = useState(languageList);

    useEffect(() => {
        let language = localStorage.getItem('pfxl');
        if (!language) {
            language = layoutState.locale;
        }

        let newLangList = [...localLangList];

        forEach(newLangList, (currency) => {
            forEach(currency.language, (lang) => {
                if (lang.id === language) {
                    lang.selected = true
                } else {
                    lang.selected = false
                }
            })
        });

        setLocalLangList(newLangList);

    }, [])

    const time = new Date(moment().format());

    useEffect(() => {
        setInterval(intervalFunc, 1000);
    }, [])

    const intervalFunc = () => {
        time.setTime(time.getTime() + 1000);
        var HH = time.getHours();


        var mm = time.getMinutes();
        var ss = time.getSeconds();

        var s_now = HH + ":" + (mm < 10 ? "0" + mm : mm) + ":" + (ss < 10 ? "0" + ss : ss);
        setLiveDate(s_now);
    }

    return (
        <>
            <div className='header-first-row'>
                <div className='header-item-container'>
                    <div className='header-first-row-left'>
                        <div className="header-datetime">({'GMT' + moment().format('Z')}) {liveDate}</div>
                        {localLangList?.map((currency) => {
                            return (
                                currency?.language.map((lang) => {
                                    if (lang?.selected) {
                                        return (
                                            <div key={lang.id} className='header-language-container' onClick={() => { setLanguageModalVisible(true) }}>
                                                <img alt={'lang'} className='language-img' src={lang?.icon} />
                                                <div>{currency?.name}</div>
                                                <div>{lang?.name}</div>
                                            </div>
                                        )
                                    }
                                })
                            )
                        })
                        }
                    </div>
                    <div className='header-first-row-right'>
                        <div className='social-media-container' onClick={() => { window.open(`${whatsAppLink ?? ''}`, 'window', 'height=500,width=400') }}>
                            <img alt={'sm'} className='social-media-img' src={whatsAppIcon} />
                            <div><FormattedMessage id='HEADER_WHATSAPP' /></div>
                        </div>
                        <Divider className='header-divider' type='vertical' />
                        <div className='social-media-container' onClick={() => { window.open(`mailto:${csEmail ?? ''}`, 'window', 'height=500,width=400') }}>
                            <img alt={'sm'} className='social-media-img' src={emailIcon} />
                            <div><FormattedMessage id='HEADER_EMAIL' /></div>
                        </div>
                        <Divider className='header-divider' type='vertical' />
                        <div className='social-media-container' onClick={() => { window.open(`${telegramLink ?? ''}`, 'window', 'height=500,width=400') }}>
                            <img alt={'sm'} className='social-media-img' src={telegramIcon} />
                            <div><FormattedMessage id='HEADER_TELEGRAM' /></div>
                        </div>
                    </div>
                </div>
            </div>
            <LanguageModal modalVisible={languageModalVisible} setModalVisible={setLanguageModalVisible} />
        </>
    )
}

export default HeaderFirstRow;