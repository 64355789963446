
import { ReactChild, ReactFragment, ReactPortal, useState } from "react";
import Marquee from "react-fast-marquee";
import { useLayout } from "../../../../../hooks/layout/LayoutContext";
import { setDBLanguageToGet } from "../../../../../shared/helpers/language.helpers";
import "./MarqueeMobile.less";

const MarqueeMobile = ({ marqueeData }: any) => {
    const { layoutState } = useLayout();

    return (
        <>
            <div className="home-marquee-mobile-container">
                <div className="home-marquee-mobile">
                    <div className="primary-mask-icon icon annoucement-icon"></div>
                    <Marquee
                        speed={40}
                        pauseOnHover
                        className='home-marquee-mobile-item'
                    >
                        {
                            marqueeData.map((marquee: {
                                id: string; message: { [x: string]: boolean | ReactChild | ReactFragment | ReactPortal | null | undefined; };
                            }) => {
                                return <div key={marquee.id} className="home-marquee-mobile-content">
                                    {marquee.message[setDBLanguageToGet(layoutState.locale)]}
                                </div>
                            })
                        }
                    </Marquee>
                </div>
            </div>
        </>
    )
}

export default MarqueeMobile;