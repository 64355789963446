import langEnLogo from '../../../../assets/images/header/lang_en.png';
import langBnLogo from '../../../../assets/images/header/lang_bn.png';

export const languageList = [
    {
        id: 'bdt',
        icon: langBnLogo,
        name: '৳ BDT',
        language: [
            {
                id: 'en',
                name: 'English',
                icon: langEnLogo,
                selected: true
            },
            {
                id: 'bn',
                name: 'বাংলা',
                icon: langBnLogo,
                selected: false
            }
        ]
    }
]