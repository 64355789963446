
import "../Auth.less";
import { Alert, Button, Checkbox, Form, Input, Select } from "antd";
import { FormattedMessage, useIntl } from 'react-intl';
import { useNavigate } from "react-router-dom";
import leftArrow from '../../../../assets/images/register/left-arrow.png';
import checkIcon from '../../../../assets/images/register/check.png';
import PhoneInput from "../../../../shared/desktop/phone-input/Phone-Input";
import { useState } from "react";

interface Props {
    form: any;
    setCurrentStep: any;
    currentStep: any;
    onFinish: any;
    authError: string;
}

const RegisterStep2 = ({ onFinish, form, setCurrentStep, currentStep, authError }: Props) => {
    const intl = useIntl();
    const navigate = useNavigate();
    const [countryId, setCountryId] = useState<string>('BD');

    const onBackClick = () => {
        setCurrentStep(currentStep - 1);
    }

    const selectCountryId = (countryId: string) => {
        setCountryId(countryId);
    }

    return (
        <Form
            form={form}
            layout="vertical"
            onFinish={onFinish}
        >
            {authError && <Alert
                className="auth-error"
                message={<FormattedMessage id={authError} />}
                type="error"
                showIcon
            />}
            <div className='reg-form-content-container'>
                <Form.Item
                    label={intl.formatMessage({ id: 'REGISTER_NAME' })}
                    name='reg_name'
                    rules={[
                        { required: true, message: intl.formatMessage({ id: 'FULLNAME_REQUIRED' }) },
                        { min: 5, message: intl.formatMessage({ id: 'AUTH_INVALID_FULLNAME' }) }
                    ]}
                >
                    <Input placeholder={intl.formatMessage({ id: 'REGISTER_NAME_PLACEHOLDER' })} />
                </Form.Item>
            </div>
            <div className="reg-form-content-container">
                <Form.Item
                    name="reg_phone"
                    label={intl.formatMessage({ id: 'REGISTER_PHONE' })}
                    rules={[
                        { required: true, message: intl.formatMessage({ id: 'PHONE_REQUIRED' }) },
                        { min: 8, message: intl.formatMessage({ id: 'AUTH_INVALID_PHONE' }) }]}>
                    <PhoneInput selectCountryId={selectCountryId} />
                </Form.Item>
            </div>
            <div className='reg-form-content-container'>
                <Form.Item
                    label={intl.formatMessage({ id: 'REGISTER_EMAIL' })}
                    name='reg_email'
                    rules={[
                        { required: true, message: intl.formatMessage({ id: 'EMAIL_REQUIRED' }) },
                        { type: "email", message: intl.formatMessage({ id: 'AUTH_INVALID_EMAIL' }) }
                    ]}
                >
                    <Input placeholder={intl.formatMessage({ id: 'REGISTER_EMAIL_PLACEHOLDER' })} />
                </Form.Item>
            </div>
            {/* <div className="reg-form-captcha-container">
                <Form.Item
                    className="captcha"
                    label={intl.formatMessage({ id: 'REGISTER_CAPTCHA' })}
                    name='reg_captcha'
                    rules={[
                        // { required: true, message: intl.formatMessage({ id: 'LOGIN_USERNAME_REQUIRED' }) },
                        { min: 5, message: intl.formatMessage({ id: 'AUTH_INVALID_CODE' }) }
                    ]}
                >
                    <Input placeholder={intl.formatMessage({ id: 'REGISTER_CAPTCHA_PLACEHOLDER' })} />
                </Form.Item>
                <div className="captcha-img">img</div>
            </div> */}
            <div className="reg-form-content-container">
                <Form.Item name="reg_legal" valuePropName="checked" rules={[
                    {
                        required: true, transform: value => (value || undefined),
                        type: 'boolean', message: intl.formatMessage({ id: 'AUTH_TNC_ENFORCE' })
                    }
                ]}>
                    <Checkbox><FormattedMessage id={'REGISTER_LEGAL_AGE'} /></Checkbox>
                </Form.Item>
            </div>
            <div className='reg-form-button-container-2'>
                <div className="reg-btn" onClick={onBackClick}>
                    <img src={leftArrow} alt='na' />
                </div>
                <Form.Item className="form-button">
                    <Button className="reg-submit-btn" htmlType="submit">
                        <img src={checkIcon} alt='na' />
                    </Button>
                </Form.Item>
            </div>
        </Form>
    );
};

export default RegisterStep2;


