
import { Layout } from 'antd';
import '../GeneralFooter.less';

import { FormattedMessage } from 'react-intl';

const FooterForthRow = () => {
    return (
        <div className='forth-row-container'>
            <div className='footer-title-container'><FormattedMessage id='FOOTER_ABOUT'/></div>
            <div className='content-container'><FormattedMessage id='FOOTER_ABOUT_CONTENT_1'/></div>
            <div className='content-container'><FormattedMessage id='FOOTER_ABOUT_CONTENT_3'/></div>
            <div className='content-container'><FormattedMessage id='FOOTER_ABOUT_CONTENT_4'/></div>
        </div>
    )
}

export default FooterForthRow;