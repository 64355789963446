
import "./BottomNavLogInMobile.less";
import { FormattedMessage } from 'react-intl';
import { useNavigate } from 'react-router-dom';
import homeIcon from '../../../assets/images/bottom-nav/icon-home.svg';
import promoIcon from '../../../assets/images/bottom-nav/icon-promotion.svg';
import depositIcon from '../../../assets/images/bottom-nav/icon-deposit.svg';
import accIcon from '../../../assets/images/bottom-nav/icon-mine.svg';

const BottomNavLogInMobile = () => {
    const navigate = useNavigate();

    const onNavClick = (nav: string) => {
        navigate(`/home${nav}`)
    }

    return (
        <>
            <div className="botom-nav-login-container">
                <div className="bottom-nav-item-container">
                    <img alt='home' src={homeIcon} onClick={()=>{onNavClick('/')}} />
                    <div className="bottom-nav-txt"><FormattedMessage id='BTM_NAV_HOME' /></div>
                </div>
                <div className="bottom-nav-item-container">
                    <img alt='home' src={promoIcon} onClick={()=>{onNavClick('/promo')}} />
                    <div className="bottom-nav-txt"><FormattedMessage id='BTM_NAV_PROMO' /></div>
                </div>
                <div className="bottom-nav-item-container" onClick={()=>{onNavClick('/funds/deposit')}}>
                    <img alt='home' src={depositIcon} />
                    <div className="bottom-nav-txt"><FormattedMessage id='BTM_NAV_DEPOSIT' /></div>
                </div>
                <div className="bottom-nav-item-container">
                    <img alt='home' src={accIcon} onClick={()=>{onNavClick('/player')}} />
                    <div className="bottom-nav-txt"><FormattedMessage id='BTM_NAV_ACC' /></div>
                </div>
            </div>
        </>

    );
};

export default BottomNavLogInMobile;
