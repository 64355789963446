
import "./HeaderMobile.less";
import backBtn from '../../../assets/images/header/icon-back.svg';
import { FormattedMessage } from 'react-intl';
import { useLocation, useNavigate } from 'react-router-dom';
import { SetStateAction } from "react";
interface Props {
    headerName: string;
    chatVisible: any;
    setChatVisible: any;
}

const DashboardHeader = ({ headerName, chatVisible, setChatVisible }: Props) => {
    const navigate = useNavigate();
    const location = useLocation();

    const onHomeClick = () => {
        navigate('/home');
    }
    const onForumClick = () => {
        navigate('/home');
    }
    const onHelpClick = () => {
        setChatVisible(chatVisible === 'hidden' ? 'maximized' : 'hidden')
    }

    return (
        <>
            <div className="header-dashboard-container">
                <div className="header-back-btn" onClick={onHomeClick} />
                <div className="header-name"><FormattedMessage id={headerName} /></div>
                <div className="right-container">
                    {/* <div className="item-column">
                        <div onClick={onForumClick} className='primary-mask-icon icon forum-icon'></div>
                        <div><FormattedMessage id='HEADER_FORUM' /></div>
                    </div> */}
                    {/* <div className="item-column">
                        <div onClick={onHelpClick} className='primary-mask-icon icon help-icon'></div>
                        <div><FormattedMessage id='HEADER_HELP' /></div>
                    </div> */}
                    <div data-id="b64ea163b3" className="livechat_button item-column">
                        <a href="https://www.livechat.com/?utm_source=chat_button&utm_medium=referral&utm_campaign=lc_14761635">
                        </a>
                    </div>
                </div>
            </div>
        </>

    );
};

export default DashboardHeader;
