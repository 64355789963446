
import "./Auth.less";
import { Form, Steps } from "antd";
import { useEffect, useState } from 'react';
import { useLazyQuery, useMutation } from '@apollo/client';
import { SET_LOGIN_STATUS, useAuth } from '../../../hooks/auth/AuthContext';
import { GET_USER_PROFILE } from '../../../shared/gql/profile.gql';
import useSpinner from '../../../hooks/layout/useSpinner';
import useNotification from '../../../hooks/layout/useNotification';
import { FormattedMessage, useIntl } from 'react-intl';
import { useLocation, useNavigate } from "react-router-dom";
import { CHECK_USERNAME, USER_SIGNUP } from "./auth.gql";
import { setTokenCookie } from "../../../shared/helpers/set-token-cookie.helper";
import RegisterStep1 from "./register-components/RegisterStep1";
import RegisterSwiper from "./register-components/RegisterSwiper";
import RegisterStep2 from "./register-components/RegisterStep2";
import { encode } from "base-64";

const Register = () => {
    const { authState, authDispatch } = useAuth();
    const { setLoading } = useSpinner();
    const intl = useIntl();
    const [getProfile, { data: getData }] = useLazyQuery(GET_USER_PROFILE, { fetchPolicy: "cache-and-network" });
    const { setSuccessNotification, setErrorNotification } = useNotification();
    const navigate = useNavigate();
    const [form1] = Form.useForm();
    const [form2] = Form.useForm();
    const [signUp, { data, loading, error }] = useMutation(USER_SIGNUP, { fetchPolicy: "no-cache", errorPolicy: "all" });
    const [currentStep, setCurrentStep] = useState(1);
    const location = useLocation();
    const query = new URLSearchParams(location.search);
    const [affReferralInput, setAffReferralInput] = useState<any>(query.get('refId') ?? "");
    const [authError, setAuthError] = useState<string>('');

    useEffect(() => {
        if (loading)
            setLoading(true);
        else
            setLoading(false);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [loading])

    useEffect(() => {
        if (data && data.userSignUp) {
            if (data.userSignUp.status === 'SUCCESS') {
                const { a, r, sid } = data.userSignUp;
                setTokenCookie(process.env.REACT_APP_ACCESS_TOKEN || 'gmf-at', a);
                setTokenCookie(process.env.REACT_APP_REFRESH_TOKEN || 'gmf-rt', r);
                setTokenCookie('gmf-sid', sid);
                localStorage.setItem('sannouncement', '1');
                authDispatch({ type: SET_LOGIN_STATUS, payload: true });
                navigate('/home');
            } else if (data.userSignUp.status === 'CODE_NOT_MATCH') {
                setErrorNotification(intl.formatMessage({ id: 'OTP_INVALID' }));
            } else if (data.userSignUp.status === 'AGE_LIMIT') {
                setErrorNotification(intl.formatMessage({ id: 'AGE_LIMIT' }));
            } else if (data.userSignUp.status === 'PHONE_EXISTED') {
                setAuthError('ERROR_PHONE_EXISTS');
            } else {
                setErrorNotification(intl.formatMessage({ id: 'GENERAL_ERROR' }));
            }
        }// eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data]);

    const onFinish = () => {
        const variables: any = {
            registerInput: {
                companyId: process.env.REACT_APP_COMPANY_ID ?? '',
                username: form1.getFieldValue('reg_username') ?? '',
                password: encode(form1.getFieldValue('reg_password')),
                currency: form1.getFieldValue('reg_currency') ?? '',
                affiliate: form1.getFieldValue('reg_referral') ?? '',
                //affiliate: regStorageJson.affiliate ?? '',
                email: form2.getFieldValue('reg_email') ?? '',
                phoneNumber: form2.getFieldValue('reg_phone') ?? '',
                //dob: new Date(moment(dobInput).startOf('day').toString()),
                fullName: form2.getFieldValue('reg_name') ?? '',
                //dialCode: dialCode,
                //url: window.location.hostname,
                //verificationType: VerificationType.PHONE
            }
        };

        signUp({ variables });
    }

    useEffect(() => {
        if (currentStep === 2) {
            const registerParam = {
                username: form1.getFieldValue('username'),
                //password: encode(form1.getFieldValue('password')),
                //affiliate: affReferralInput ?? ''
            };

            localStorage.setItem("regParam", JSON.stringify(registerParam));
        }
    }, [currentStep])

    return (
        <div className="register-container">
            <div className="contact-support-container">
                <div className="contact-support"><FormattedMessage id='REGISTER_CONTACT_SUPPORT' /></div>
                <div className="contact-support-link"><FormattedMessage id='REGISTER_CONTACT_SUPPORT_LINK' /></div>
            </div>
            <div className="register-content-container">
                <div className="register-form-container">
                    <div className="register-form-title"><FormattedMessage id='REGISTER_TITLE' /></div>
                    <div className="register-form">
                        {
                            currentStep === 1 &&
                            <RegisterStep1 affReferralInput={affReferralInput} setAffReferralInput={setAffReferralInput} currentStep={currentStep}
                                setCurrentStep={setCurrentStep} form={form1} isFromQueryString={query.get('refId') ? true : false} />
                        }
                        {
                            currentStep === 2 &&
                            <RegisterStep2 onFinish={onFinish} currentStep={currentStep} setCurrentStep={setCurrentStep} form={form2}
                                authError={authError} />
                        }
                    </div>
                </div>
                <RegisterSwiper />
            </div>
        </div>
    );
};

export default Register;

