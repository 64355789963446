
import { Divider, Spin } from 'antd';
import { useEffect, useState } from 'react';
import '../../GeneralHeader.less';
import logo from '../../../../../assets/images/header/logo.png';
import { FormattedMessage, useIntl } from 'react-intl';
import LoginModal from '../../../modal/LoginModal/LoginModal';
import { useNavigate } from 'react-router-dom';
import { CLEAR_CURRENT_USER, SET_LATEST_BALANCE, SET_LOGIN_STATUS, useAuth } from '../../../../../hooks/auth/AuthContext';
import { useLazyQuery, useMutation } from '@apollo/client';
import { LOGOUT, SET_PASSWORD, SIGN_IN } from '../../../../../pages/desktop/auth/auth.gql';
import { clearAllTokens, setTokenCookie } from '../../../../helpers/set-token-cookie.helper';
import useSpinner from '../../../../../hooks/layout/useSpinner';
import useNotification from '../../../../../hooks/layout/useNotification';
import NumberFormat from 'react-number-format';
import { WALLET_BALANCE } from '../../../../../pages/desktop/home/Home.gql';

const HeaderSecondRow = () => {
    const intl = useIntl();
    const [loginModalVisible, setLoginModalVisible] = useState(false);
    const { setLoading } = useSpinner();
    const { authState, authDispatch } = useAuth();
    const { setSuccessNotification, setErrorNotification } = useNotification();
    const [logout, { data: logoutData, loading: logoutLoading }] = useMutation(LOGOUT, { fetchPolicy: "no-cache", errorPolicy: "all" });
    const [signIn, { data, loading, error: signInError }] = useMutation(SIGN_IN, {
        fetchPolicy: "no-cache",
        errorPolicy: "all",
    });
    const [submitNewPassword, { data: setPasswordData }] = useMutation(SET_PASSWORD,
        { errorPolicy: 'all' });
    const [getWalletBalance, { data: walletBalanceData, loading: walletBalanceLoading }] = useLazyQuery(WALLET_BALANCE);
    const navigate = useNavigate();

    useEffect(() => {
        if (logoutLoading || loading)
            setLoading(true)
        else
            setLoading(false)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [logoutLoading, loading])

    useEffect(() => {
        if (data && data.signIn) {
            const { a, r, sid } = data.signIn;
            setTokenCookie(process.env.REACT_APP_ACCESS_TOKEN || "gmf-at", a);
            setTokenCookie(process.env.REACT_APP_REFRESH_TOKEN || "gmf-rt", r);
            setTokenCookie("gmf-sid", sid);
            localStorage.setItem('sannouncement', '1');
            authDispatch({ type: SET_LOGIN_STATUS, payload: true });
            setLoginModalVisible(false);
            navigate('/home');
        } // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data]);

    useEffect(() => {
        if (signInError) {
            if (signInError.message?.indexOf("INVALID_LOGIN") > -1 || signInError.message?.indexOf("INVALID_PHONE_LOGIN") > -1) {
                setErrorNotification(intl.formatMessage({ id: `AUTH_ERROR_INVALID_LOGIN` }));
            } else if (signInError.message?.indexOf("ACCOUNT_LOCKED") > -1) {
                setErrorNotification(intl.formatMessage({ id: `AUTH_ERROR_ACCOUNT_LOCKED` }));
            }
        } // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [signInError]);

    useEffect(() => {
        if (logoutData && logoutData.logout) {
            clearAllTokens();
            authDispatch({ type: CLEAR_CURRENT_USER });
            authDispatch({ type: SET_LOGIN_STATUS, payload: false });
            localStorage.setItem('sannouncement', '0');
            navigate('/home');
        } // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [logoutData])

    useEffect(() => {
        if (setPasswordData && setPasswordData.setNewPassword) {
            if (setPasswordData.setNewPassword.status === 'SUCCESS') {
                setSuccessNotification(intl.formatMessage({ id: 'RESET_PASSWORD_SUCCESS' }));
                setLoginModalVisible(false);
                navigate('/home');
            } else {
                setErrorNotification(intl.formatMessage({ id: `RESET_PASSWORD_FAILED` }));
            }
        } // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [setPasswordData]);

    useEffect(() => {
        if (walletBalanceData && walletBalanceData.walletBalance) {
            authDispatch({ type: SET_LATEST_BALANCE, payload: walletBalanceData.walletBalance.totalBalance ?? 0 });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [walletBalanceData]);

    const onLoginClick = () => {
        setLoginModalVisible(true);
    }

    const onRegisterClick = () => {
        navigate('/home/register');
    }

    const onHomeClick = () => {
        navigate('/home');
    }

    const onLogoutClick = () => {
        logout();
    }

    const onMemberCenterClick = () => {
        navigate('/home/dashboard/profile');
    }

    const onDepositClick = () => {
        navigate('/home/dashboard/walletDeposit');
    }

    return (
        <>
            <div className='header-second-row'>
                <div className='header-item-container'>
                    <div className='header-second-row-left'>
                        <img alt={'logo'} onClick={onHomeClick} className='header-logo-container' src={logo} />
                        <Divider className='header-divider' type='vertical' />
                    </div>
                    <div className='header-second-row-right'>
                        {authState?.isLogin ?
                            <>
                                <div><FormattedMessage id='DASHBOARD_MAIN_WALLET' /></div>
                                {
                                    walletBalanceLoading ?
                                        <Spin className='balance-loading' /> :
                                        <div className="header-wallet-container">
                                            <FormattedMessage id={`CURRENCY_SYMBOL_${authState.userProfile?.currency ?? 'BDT'}`} />
                                            <NumberFormat displayType="text" value={authState?.totalBalance ? authState?.totalBalance.toFixed(2) : 0} thousandSeparator={true} />
                                        </div>
                                }
                                <div className='reload-icon' onClick={() => { getWalletBalance() }} />
                                <div className='register-btn' onClick={onDepositClick}><FormattedMessage id='BTN_DEPOSIT' /></div>
                                <div className='login-btn' onClick={onMemberCenterClick}><FormattedMessage id='BTN_MEMBER_CENTER' /></div>
                                <div className='logout-btn' onClick={onLogoutClick}><FormattedMessage id='BTN_LOGOUT' /></div>
                            </> :
                            <>
                                <div className='login-btn' onClick={onLoginClick}><FormattedMessage id='BTN_LOGIN' /></div>
                                <div className='register-btn' onClick={onRegisterClick}><FormattedMessage id='BTN_SIGN_UP' /></div>
                            </>}
                    </div>
                </div>
            </div>
            <LoginModal submitNewPassword={submitNewPassword} loading={loading} signIn={signIn} modalVisible={{ visible: loginModalVisible, setVisible: setLoginModalVisible }} />
        </>
    )
}

export default HeaderSecondRow;