import { FormattedMessage } from "react-intl";

import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Pagination, Navigation, Autoplay } from "swiper";

import favImg1 from '../../../../assets/images/fav_img_1.jpg';
import favImg2 from '../../../../assets/images/fav_img_2.jpg';
import favImg3 from '../../../../assets/images/fav_img_3.jpg';
import favImg4 from '../../../../assets/images/fav_img_4.jpg';
import { setDBLanguageToGet } from "../../../../shared/helpers/language.helpers";
import { useLayout } from "../../../../hooks/layout/LayoutContext";
import { Spin } from "antd";

interface Props {
    bannerData: any;
    bannerData1: any;
}

const FavoriteCarousels = ({ bannerData, bannerData1 }: Props) => {
    const { layoutState } = useLayout();

    return (
        <>
            <div className="home-fav-carousels-container">

                {bannerData && bannerData.length > 0 ?
                    <>
                        <div className="fav-container">
                            <div className="fav-title"><FormattedMessage id='HOME_FAV_TITLE' /></div>
                            <Swiper
                                slidesPerView={"auto"}
                                grabCursor={true}
                                spaceBetween={30}
                                slidesPerGroup={1}
                                loop={true}
                                autoplay
                                loopFillGroupWithBlank={true}
                                pagination={false}
                                navigation={true}
                                modules={[Pagination, Navigation]}
                                slideToClickedSlide={true}
                                className="mySwiper">
                                {bannerData && bannerData.map((banner: any) => {
                                    return (
                                        <SwiperSlide key={banner.id}>
                                            <div key={banner.id}>
                                                <img alt={banner.id} className="carousel-image"
                                                    src={JSON.parse(banner?.pictureUrl)[setDBLanguageToGet(layoutState.locale)]}
                                                />
                                            </div>
                                        </SwiperSlide>
                                    );
                                })}
                            </Swiper>
                        </div>
                        <div className="fav-container">
                            <div className="fav-title"><FormattedMessage id='HOME_FAV_TITLE' /></div>
                            <Swiper
                                slidesPerView={"auto"}
                                grabCursor={true}
                                spaceBetween={30}
                                slidesPerGroup={1}
                                loop={true}
                                autoplay
                                loopFillGroupWithBlank={true}
                                pagination={false}
                                navigation={true}
                                modules={[Pagination, Navigation]}
                                slideToClickedSlide={true}
                                className="mySwiper">
                                {bannerData1 && bannerData1.map((banner: any) => {
                                    return (
                                        <SwiperSlide key={banner.id}>
                                            <div key={banner.id}>
                                                <img alt={banner.id} className="carousel-image"
                                                    src={JSON.parse(banner?.pictureUrl)[setDBLanguageToGet(layoutState.locale)]}
                                                />
                                            </div>
                                        </SwiperSlide>
                                    );
                                })}
                            </Swiper>
                        </div>
                    </>
                    :
                    <Spin />}
            </div>
        </>
    )
}

export default FavoriteCarousels;