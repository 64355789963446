import { gql } from "@apollo/client";

export const GET_SUPPORT_CONFIGURATIONS = gql`
query SupportConfigurations($companyId:String!){
  supportConfigurations(companyId:$companyId){
      id,
      name,
      value,
      inputType
  }
}
`;