import { Suspense, useEffect, useState, useRef } from 'react';
import { Routes, Route, useNavigate, useLocation } from 'react-router-dom';
import { Layout, Spin, notification } from 'antd';
import { CLEAR_CURRENT_USER, SET_CURRENT_USER, SET_LOGIN_STATUS, SET_MAINTENANCE_LIST, SET_USER_WALLET, useAuth } from './hooks/auth/AuthContext';
import { CLEAR_NOTIFICATION, useLayout } from './hooks/layout/LayoutContext';
import { routes, MobileRouteInfo } from './routes-mobile';
import { useIntl } from 'react-intl';
import Cookies from 'universal-cookie';
import { useLazyQuery, useMutation, useQuery } from '@apollo/client';
import { GET_MAINTENANCE_LIST, GET_USER_PROFILE } from './shared/gql/profile.gql';
import HomeMobile from './pages/_mobile/home/HomeMobile';
import { getDatabase, ref, child, off, onValue } from "firebase/database";
import { initializeApp } from "firebase/app";
import { LOGOUT } from './pages/desktop/auth/auth.gql';
import { clearAllTokens } from './shared/helpers/set-token-cookie.helper';
import useSpinner from './hooks/layout/useSpinner';
import HeaderMobile from './shared/_mobile/header/HeaderMobile';
import FooterMobile from './shared/_mobile/footer/FooterMobile';
import './AppMobile.less';
import BottomNavMobile from './shared/_mobile/bottom-navigation/BottomNavMobile';
import SideNavMobile from './shared/_mobile/side-nav/SideNavMobile';
import BottomNavLogInMobile from './shared/_mobile/bottom-navigation/BottomNavLogInMobile';
import { getFooterType, getHeaderName, getHeaderType } from './shared/helpers/layout-helpers';
import DashboardHeader from './shared/_mobile/header/DashboardHeader';
import RegisterMobile from './pages/_mobile/auth/register/RegisterMobile';
import { WALLET_BALANCE } from './pages/desktop/home/Home.gql';
import { LiveChatWidget, EventHandlerPayload } from "@livechat/widget-react";
import cuid from "cuid";
import logo from './assets/images/bdt.png';
import { CustomSpinner } from './shared/desktop/spinner/CustomSpinner';

const cookies = new Cookies();
const { Content } = Layout;

const AppMobileProtected = () => {
    const intl = useIntl();
    const location = useLocation();
    const { setLoading } = useSpinner();
    const navigate = useNavigate();
    const [collapsed, setCollaped] = useState(window.innerWidth < 769 ? true : false);
    const { authState, authDispatch } = useAuth();
    const { layoutState, layoutDispatch } = useLayout();
    const [listener, setListener] = useState<any>();
    const refreshTokenKey = process.env.REACT_APP_REFRESH_TOKEN || 'prod-gmf-rt';
    const [showSideNav, setShowSideNav] = useState(false);
    const [livechatId, setLiveChatId] = useState("");

    const [getUserProfile, { data, error, called, refetch }] = useLazyQuery(GET_USER_PROFILE, {
        fetchPolicy: 'no-cache',
        errorPolicy: "all"
    });
    // const [getUserWallets, { data: walletData, called: walletCalled, refetch: walletRefetch }] = useLazyQuery(GET_WALLETS,
    //     { fetchPolicy: "no-cache" });
    const [getMaintenanceList, { data: maintenanceData, error: maintenanceError, called: maintenanceCalled, refetch:
        maintenanceRefetch }] = useLazyQuery(GET_MAINTENANCE_LIST, {
            fetchPolicy: 'no-cache',
        });
    const [getWalletBalance, { data: walletBalanceData, called: walletBalanceCalled, refetch: walletBalanceRefetch }] =
        useLazyQuery(WALLET_BALANCE, { fetchPolicy: 'no-cache' });
    const [logout, { data: logoutData, loading: logoutLoading }] = useMutation(LOGOUT, { fetchPolicy: "no-cache", errorPolicy: "all" });

    const onFocus = (isUserLogin: boolean) => {
        if (document.visibilityState === 'visible' && isUserLogin === true) {
            //getUserWallets();
        }
    };
    const [chatVisibility, setChatVisibility] = useState<any>('hidden');

    useEffect(() => {
        if (logoutLoading) {
            setLoading(true);
        } else {
            setLoading(false);
        }// eslint-disable-next-line react-hooks/exhaustive-deps
    }, [logoutLoading])

    useEffect(() => {
        //document.addEventListener('visibilitychange', onFocus);
        // Calls onFocus when the window first loads
        onFocus(authState.isLogin);
        maintenanceCalled && maintenanceRefetch ? maintenanceRefetch() : getMaintenanceList();
    }, []);

    useEffect(() => {
        if (document.visibilityState === 'visible') {
            //console.log('tab is visible');
        }
    }, [document.visibilityState])

    useEffect(() => {
        if (walletBalanceData && walletBalanceData.walletBalance) {
            authDispatch({ type: SET_USER_WALLET, payload: { wallets: null, walletCategory: null, totalBalance: +walletBalanceData.walletBalance.totalBalance, bonusWalletType: null } });
        }
    }, [walletBalanceData])


    useEffect(() => {
        if (authState.isLogin) {
            document.addEventListener('visibilitychange', () => { onFocus(true) });
            called && refetch ? refetch() : getUserProfile();
            //walletCalled && walletRefetch ? walletRefetch() : getUserWallets();
            walletBalanceCalled && walletBalanceRefetch ? walletBalanceRefetch() : getWalletBalance();
            maintenanceCalled && maintenanceRefetch ? maintenanceRefetch() : getMaintenanceList();
        } else {
            if (listener) {
                off(listener);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [authState.isLogin]);

    useEffect(() => {
        if (logoutData && logoutData.logout) {
            clearAllTokens();
            authDispatch({ type: CLEAR_CURRENT_USER });
            authDispatch({ type: SET_LOGIN_STATUS, payload: false });
            localStorage.setItem('sannouncement', '0');
            navigate('/home');
            //window.location.reload();
        } // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [logoutData])

    const toggleSideNav = () => {
        setCollaped(!collapsed);
    };

    //Set user profile is profile data changed
    useEffect(() => {
        if (data && !error) {
            authDispatch({ type: SET_CURRENT_USER, payload: data });

            //firebase
            if (data?.userProfile && data?.userProfile?.id && authState.isLogin) {
                localStorage.setItem("ua", data?.userProfile?.ua);
                const firebaseConfig = {
                    databaseURL: "https://whitelabelweb-22017-default-rtdb.asia-southeast1.firebasedatabase.app",
                };
                const app = initializeApp(firebaseConfig);
                const db = getDatabase(app);
                const listener = ref(db, process.env.REACT_APP_ENV + data?.userProfile?.id);
                setListener(listener);
                onValue(listener, (snapshot) => {
                    const firebase = snapshot.val();
                    const newUa = localStorage.getItem("ua");
                    if (newUa && firebase.ua) {
                        if (firebase.ua.toString() != newUa) {
                            localStorage.removeItem("ua");
                            logout();
                            off(listener);
                        }
                    }
                });
            }
        } else if (error) {
            authDispatch({ type: SET_LOGIN_STATUS, payload: false });
        } // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data, error]);

    useEffect(() => {
        if (maintenanceData && !maintenanceError) {
            authDispatch({ type: SET_MAINTENANCE_LIST, payload: maintenanceData?.maintenanceList?.providerIdList ?? null });
        } // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [maintenanceData, maintenanceError]);

    useEffect(() => {
        if (layoutState.showNotification && layoutState.showNotification.message) {
            openNotificationWithIcon();
        }// eslint-disable-next-line react-hooks/exhaustive-deps
    }, [layoutState.showNotification])

    useEffect(() => {
        if (!cookies.get(refreshTokenKey) && authState.isLogin) {
            authDispatch({ type: SET_LOGIN_STATUS, payload: false });
            navigate('/auth', { replace: true })
        }// eslint-disable-next-line react-hooks/exhaustive-deps
    }, [cookies.get(refreshTokenKey)]);

    const openNotificationWithIcon = () => {
        notification[layoutState?.showNotification?.type]({
            message: layoutState?.showNotification.message,
            description: layoutState?.showNotification?.description,
        });
        layoutDispatch({ type: CLEAR_NOTIFICATION })
    };

    const protectedRoutesAccessCheck = (route: MobileRouteInfo) => {
        return (<Suspense fallback={<HomeMobile setLiveChatId={setLiveChatId} />}>
            <route.component routes={route.routes} />
        </Suspense>);
    }

    const renderHeader = () => {
        let headerType = getHeaderType(location.pathname);

        if (headerType === 'DASHBOARD') {
            return <DashboardHeader headerName={getHeaderName(location.pathname)} chatVisible={chatVisibility} setChatVisible={setChatVisibility} />
        } else if (headerType === 'MOBILE') {
            return <HeaderMobile setShowSideNav={setShowSideNav} chatVisible={chatVisibility} setChatVisible={setChatVisibility} />
        }
    }

    const renderFooter = () => {
        let footerType = getFooterType(location.pathname);

        let bottomNav = authState?.isLogin ? <BottomNavLogInMobile /> : <BottomNavMobile />

        if (footerType === 'FULL') {
            return <>
                <FooterMobile />
                {bottomNav}
            </>
        } else if (footerType === 'BTMNAV') {
            return <>
                {bottomNav}
            </>
        }
    }

    const handleNewEvent = (event: EventHandlerPayload<"onNewEvent">) => {
        // console.log("LiveChatWidget.onNewEvent", event);
    }

    return (<Spin indicator={<CustomSpinner/>} spinning={layoutState.showLoading}>
        <Layout className="app-mobile-container">
            <LiveChatWidget
                key={livechatId}
                license="14761635"
                visibility={chatVisibility}
                onNewEvent={handleNewEvent}
            />
            <Layout className="site-layout">
                <SideNavMobile showSideNav={showSideNav} setShowSideNav={setShowSideNav} />
                {renderHeader()}
                <Content
                    className="m-site-layout-background">
                    <Routes>
                        <Route path='' element={<HomeMobile setLiveChatId={setLiveChatId} />} />
                        <Route path="/referral/*" element={
                            <Suspense fallback={<Spin tip={intl.formatMessage({ id: "SPINNER_TIP" })} />}>
                                <RegisterMobile />
                            </Suspense>
                        } />
                        {routes.map((route: MobileRouteInfo, i) => {
                            return (
                                <Route key={i} path={`${route.path}`} element={
                                    protectedRoutesAccessCheck(route)
                                } />
                            );
                        })}
                    </Routes>
                </Content>
                {renderFooter()}
            </Layout>
        </Layout>
    </Spin>);
}

export default AppMobileProtected;