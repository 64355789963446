
import { Layout } from 'antd';
import './GeneralHeader.less';
import HeaderFirstRow from './HeaderElements/first-row/HeaderFirstRow';
import HeaderSecondRow from './HeaderElements/second-row/HeaderSecondRow';

const { Header } = Layout;

interface Props {
  telegramLink: string;
  fbLink: string;
  csEmail: string;
  whatsAppLink: string;
}

const GeneralHeader = ({telegramLink, fbLink, csEmail, whatsAppLink}: Props) => {
  return (
    <Header className="site-layout-header" >
      <HeaderFirstRow telegramLink={telegramLink} fbLink={fbLink} csEmail={csEmail} whatsAppLink={whatsAppLink} />
      <HeaderSecondRow />
    </Header>
  )
}

export default GeneralHeader;