
import "./BottomNavMobile.less";
import { FormattedMessage } from 'react-intl';
import { useNavigate } from 'react-router-dom';
import bdt from '../../../assets/images/bdt.png';
import LanguageModal from "../../desktop/modal/LanguageModal/LanguageModal";
import { useEffect, useState } from "react";
import { languageList } from "../../desktop/modal/LanguageModal/LanguageList";
import { forEach } from "lodash";
import { useLayout } from "../../../hooks/layout/LayoutContext";

const BottomNavMobile = () => {
    const navigate = useNavigate();
    const { layoutState, layoutDispatch } = useLayout();
    const [languageModalVisible, setLanguageModalVisible] = useState(false);
    const [localLangList, setLocalLangList] = useState(languageList);

    useEffect(() => {
        let language = localStorage.getItem('pfxl');
        if (!language) {
            language = layoutState.locale;
        }

        let newLangList = [...localLangList];

        forEach(newLangList, (currency) => {
            forEach(currency.language, (lang) => {
                if (lang.id === language) {
                    lang.selected = true
                } else {
                    lang.selected = false
                }
            })
        });

        setLocalLangList(newLangList);

    }, [])

    const onLanguageClick = () => {
        setLanguageModalVisible(true);
    }

    return (
        <>
            <div className="botom-nav-mobile-container">
                {localLangList?.map((currency) => {
                    return (
                        currency?.language.map((lang) => {
                            if (lang?.selected) {
                                return (
                                    <div key={lang?.id} className="m-language-container" onClick={onLanguageClick}>
                                        <img src={lang?.icon} alt="language" className="language-logo" />
                                        <div className="m-lang-content">
                                            <div className="m-lang-currency">{currency?.name}</div>
                                            <div className="m-lang-lang">{lang?.name}</div>
                                        </div>
                                    </div>
                                )
                            }
                        })
                    )
                })
                }
                <div className="m-register" onClick={() => { navigate('/home/register') }}><div><FormattedMessage id="BTM_NAV_SIGNUP" /></div></div>
                <div className="m-login" onClick={() => { navigate('/home/login') }}><div><FormattedMessage id="BTM_NAV_LOGIN" /></div></div>
            </div>
            <LanguageModal modalVisible={languageModalVisible} setModalVisible={setLanguageModalVisible} />
        </>

    );
};

export default BottomNavMobile;
