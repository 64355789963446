
import { Button } from 'antd';
import { FormattedMessage } from 'react-intl';
import './PrimaryButton.less';

interface Props {
    btnText: string;
    isFormBtn: boolean;
    onClick: any;
    additionalClassName?: string;
    loading?: boolean;
    disabled?: boolean;
}

const PrimaryButton = ({ btnText, isFormBtn, onClick, additionalClassName = "primary-btn", loading, disabled }: Props) => {
    return (
        <>
            <Button block type="primary" htmlType={isFormBtn ? "submit" : "button"}
                className={additionalClassName} onClick={onClick} loading={loading}
                disabled={disabled}>
                <FormattedMessage id={btnText} />
            </Button>
        </>
    )
}

export default PrimaryButton;