import { Layout } from "antd";
const { Content } = Layout;

const NoMatch = () => {
    return (
        <Layout>
            <Content className="anonymous-container">
                <div className="anonymous-wrapper">
                    404
                </div>
            </Content>
        </Layout>
    );
}

export default NoMatch;