import paymentBankDepositIcon from '../../../assets/images/footer/payment_1.png';
import paymentbKashIcon from '../../../assets/images/footer/payment_2.png';
import payment3Icon from '../../../assets/images/footer/payment_3.png';
import payment4Icon from '../../../assets/images/footer/payment_4.png';
import payment5Icon from '../../../assets/images/footer/payment_5.png';
import payment6Icon from '../../../assets/images/footer/payment_6.png';
import payment7Icon from '../../../assets/images/footer/payment_7.png';
import payment8Icon from '../../../assets/images/footer/payment_8.png';
import paymentUsdtIcon from '../../../assets/images/footer/payment_9.png';

import safe1Icon from '../../../assets/images/footer/safe_1.png';
import safe2Icon from '../../../assets/images/footer/safe_2.png';
import safe3Icon from '../../../assets/images/footer/safe_3.png';

import facebookIcon from '../../../assets/images/footer/FaceBook.png';
import instagramIcon from '../../../assets/images/footer/Instagram.png';
import twitterIcon from '../../../assets/images/footer/Twitter.png';
import pinterestIcon from '../../../assets/images/footer/Pinterest.png';
import youtubeIcon from '../../../assets/images/footer/Youtube.png';

import gameLicenseIcon from '../../../assets/images/footer/gaming_license.png';
import androidDl from '../../../assets/images/footer/android_download.png';

export const paymentMethods: PaymentMethod[] = [
    { 'id': 'bankDeposit', 'label': 'PAYMENT_BANK_DEPOSIT', 'icon': paymentBankDepositIcon, 'url': '' },
    { 'id': 'bKash', 'label': '', 'icon': paymentbKashIcon, 'url': '' },
    { 'id': 'payment3', 'label': '', 'icon': payment3Icon, 'url': '' },
    { 'id': 'payment4', 'label': '', 'icon': payment4Icon, 'url': '' },
    { 'id': 'payment5', 'label': '', 'icon': payment5Icon, 'url': '' },
    // { 'id': 'payment6', 'label': '', 'icon': payment6Icon, 'url': '' },
    // { 'id': 'payment7', 'label': '', 'icon': payment7Icon, 'url': '' },
    // { 'id': 'payment8', 'label': '', 'icon': payment8Icon, 'url': '' },
    { 'id': 'paymentUsdt', 'label': '', 'icon': paymentUsdtIcon, 'url': '' },
];

export const responsible: Responsible[] = [
    { 'id': 'responsible1', 'label': 'responbile 1', 'icon': safe1Icon, 'url': 'https://www.gamcare.org.uk/' },
    { 'id': 'responsible2', 'label': 'responbile 2', 'icon': safe2Icon, 'url': 'https://www.betlian.live/account/18-policy/' },
    { 'id': 'responsible3', 'label': 'responbile 3', 'icon': safe3Icon, 'url': 'https://www.betlian.live/responsible-gaming/' },
];

export const community: Community[] = [
    { 'id': 'facebook', 'label': 'facebook', 'icon': facebookIcon, 'url': 'https://www.facebook.com/betlian.net' },
    { 'id': 'instagram', 'label': 'instagram', 'icon': instagramIcon, 'url': 'https://www.instagram.com/accounts/login/?next=%2Fbetlian.live%2F&source=omni_redirect' },
    // { 'id': 'pinterest', 'label': 'pinterest', 'icon': pinterestIcon, 'url': 'https://bit.ly/3NxiuUx' },
    // { 'id': 'twitter', 'label': 'twitter', 'icon': twitterIcon, 'url': 'https://bit.ly/3bqs1Pv' },
    { 'id': 'youtube', 'label': 'youtube', 'icon': youtubeIcon, 'url': 'https://www.youtube.com/@betlian' },
];
export const license: License[] = [
    { 'id': 'gameLicense', 'label': 'game license', 'icon': gameLicenseIcon, 'url': '' },
    { 'id': 'androidapk', 'label': 'android apk', 'icon': androidDl, 'url': 'https://whitelabelgeneraldev.blob.core.windows.net/apk/betlian.apk' },
];

export interface PaymentMethod {
    id: string;
    label: string;
    icon: string;
    url?: string;
}
export interface Responsible {
    id: string;
    label: string;
    icon: string;
    url?: string;
}
export interface Community {
    id: string;
    label: string;
    icon: string;
    url?: string;
}
export interface License {
    id: string;
    label: string;
    icon: string;
    url?: string;
}