
import "../Auth.less";
import backgroundImg from '../../../../assets/images/register/innerpage-register-bg.jpg';
import backgroundImg2 from '../../../../assets/images/register/innerpage-register-bg-2.jpg';
import backgroundImg3 from '../../../../assets/images/register/innerpage-register-bg-3.jpg';
import backgroundImg4 from '../../../../assets/images/register/innerpage-register-bg-4.jpg';
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Pagination, Navigation, Autoplay } from "swiper";

SwiperCore.use([Autoplay]);

const RegisterSwiper = () => {

    const regBannerList = [
        {
            id: '1',
            img: backgroundImg
        },
        {
            id: '2',
            img: backgroundImg2
        },
        {
            id: '3',
            img: backgroundImg3
        },
        {
            id: '4',
            img: backgroundImg4
        },
    ]

    return (
        <div className="register-swiper-container">
            <Swiper
                autoplay={true}
                grabCursor={true}
                slidesPerView={1}
                spaceBetween={0}
                slidesPerGroup={1}
                loop={true}
                loopFillGroupWithBlank={true}
                pagination={{
                    clickable: true,
                }}
                navigation={false}
                modules={[Pagination, Navigation]}
                className="mySwiper"
            >
                {regBannerList && regBannerList?.map((banner: any) => {
                    return (
                        <SwiperSlide key={banner.id}>
                            <div key={banner.id}>
                                <img alt={banner.id} className="carousel-image"
                                    src={banner?.img}//{JSON.parse(banner?.pictureUrl)[setDBLanguageToGet(layoutState.locale)]}
                                />
                            </div>
                        </SwiperSlide>

                    );
                })}
            </Swiper>
        </div>
    );
};

export default RegisterSwiper;

