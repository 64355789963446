
import { Layout } from 'antd';
import '../GeneralFooter.less';


import sm1Icon from '../../../../assets/images/footer/sm_icon1.png';
import sm2Icon from '../../../../assets/images/footer/sm_icon2.png';
import sm3Icon from '../../../../assets/images/footer/sm_icon3.png';
import sm4Icon from '../../../../assets/images/footer/sm_icon4.png';
import sm5Icon from '../../../../assets/images/footer/sm_icon5.png';

import licenseImg from '../../../../assets/images/footer/license.png';

import { FormattedMessage } from 'react-intl';

const FooterThirdRow = () => {
    return (
        <div className='third-row-container'>
            <div className='content-container'>
                <div className='title-txt'><FormattedMessage id='FOOTER_RESPONSIBLE'/></div>
                <div className='payment-icon-container'>
                    <img alt={'sm'} className='payment-icon' src={sm1Icon} onClick={()=>{window.open('https://www.facebook.com/betlian.net', 'window')}}/>
                    <img alt={'sm'} className='payment-icon' src={sm2Icon} onClick={()=>{window.open('https://www.instagram.com/accounts/login/?next=%2Fbetlian.live%2F&source=omni_redirect', 'window')}}/>
                    {/* <img alt={'sm'} className='payment-icon' src={sm3Icon} onClick={()=>{window.open('https://bit.ly/3NxiuUx', 'window')}}/> */}
                    {/* <img alt={'sm'} className='payment-icon' src={sm4Icon} onClick={()=>{window.open('https://bit.ly/3bqs1Pv', 'window')}}/> */}
                    <img alt={'sm'} className='payment-icon' src={sm5Icon} onClick={()=>{window.open('https://www.youtube.com/@betlian', 'window')}}/>
                </div>
            </div>
            <div className='content-container'>
                <div className='title-txt'><FormattedMessage id='FOOTER_LICENSE'/></div>
                <div className='payment-icon-container'>
                    <img alt={'license'} className='license-img' src={licenseImg}/>
                </div>
            </div>
        </div>
    )
}

export default FooterThirdRow;