
import "./FavouriteMobile.less";

import { FormattedMessage } from 'react-intl';
import { useAuth } from "../../../../../hooks/auth/AuthContext";
import { setDBLanguageToGet } from "../../../../../shared/helpers/language.helpers";
import { useLayout } from "../../../../../hooks/layout/LayoutContext";

interface Favourite {
    id: string;
    desc: string;
    imageUrl: string;
}

interface Props {
    bannerData: any;
}

const FavouriteMobile = ({ bannerData }: Props) => {
    const { layoutState } = useLayout();

    return (
        <div className="favourite-mobile-container p">
            <div>
                <h2 className="title"><FormattedMessage id="HOME_FAV_TITLE" /></h2>
            </div>
            <div className="favourite-card-wrapper">
                {
                    bannerData.map((f: any) => {
                        return <div key={f?.id} className="favourite-card"><img key={f.id} src={JSON.parse(f?.pictureUrl)[setDBLanguageToGet(layoutState.locale)]} alt={f.desc} /></div>
                    })
                }
            </div>
        </div>
    );
};

export default FavouriteMobile;
