import { gql } from "@apollo/client";

export const GET_USER_PROFILE = gql`
query UserProfile {
    userProfile {
		id,
		name,
		userName,
		phone,
		phoneVerified,
		email,
		emailVerified,
		gender,
		bankAccountStatus,
		loginPasswordStatus,
		isActive,
		requiredPasswordChange,
		address1,
		address2,
		city,
		state,
		postcode,
		dob,
		currency,
		referralCode,
		siteUrl,
		dobUpdated,
		ua,
		currentTime,
		registerTime,
		lastWithdrawal,
		lastDeposit,
		currentVipPoint,
		prevVipRankPoint,
		nextVipRankPoint,
		currentVipRankName,
		nextVipRankName,
		logoUrl,
		pointExchangeRate
    }
}
`;

export const GET_MAINTENANCE_LIST = gql`
query MaintenanceList {
    maintenanceList {
		providerIdList
    }
}
`;
