export const getHeaderType = (location: string) => {
    if (location.includes('/home/slot/') || location.includes('/home/fishing/') || location.includes('/home/casino/')
        || location.includes('/home/promo') || location.includes('/home/table')
    ) {
        return 'MOBILE';
    } else {
        switch (location) {
            case "/home/vip":
            case "/home/promotion":
            case "/home/":
            case "/home":
            case "/home/login":
            case "/home/register":
            case "/home/forgot-password":
                return 'MOBILE';
            case "/home/player":
                return 'NONE';
            default:
                return 'DASHBOARD';
        }
    }
}

export const getFooterType = (location: string) => {
    switch (location) {
        case "/home/login":
        case "/home/register":
        case "/home/player":
        case "/home/forgot-password":
            return 'NONE';
        case "/home/promotion":
        case "/home/":
        case "/home":
            return 'FULL';
        default:
            return 'BTMNAV';

    }
}

export const getHeaderName = (location: string) => {
    switch (location) {
        case "/home/reset-password":
            return "M_MENU_RESET_PASSWORD";
        case "/home/funds/deposit":
        case "/home/funds/withdrawal":
            return "M_MENU_FUNDS";
        case "/home/betting-records":
            return "M_MENU_BET_RECORD";
        case "/home/turnover":
            return "M_MENU_TURNOVER";
        case "/home/trans-history":
            return "M_MENU_TRANSACTION";
        case "/home/profile":
            return "M_MENU_PERSONAL_INFO";
        case "/home/inbox":
            return "M_MENU_INBOX";
        case "/home/referral":
            return "M_MENU_REFERRAL";
        case "/home/referralcode":
            return "M_MENU_REFERRAL";
        case "/home/promo":
            return "M_MENU_PROMO";
        case "/home/transaction-history":
            return "M_MENU_TRANSACTION"
        case "/home/forgot-password":
            return "M_MENU_RESET_PASSWORD"
        case "/home/vip-account":
            return "M_VIP_TITLE";
        case "/home/vp-transactions":
            return "M_MENU_VP_TRANSACTIONS";
        case "/home/vip-rank-history":
            return "M_MENU_VIP_RANK_HISTORY";
        default:
            return ""
    }
}