import React from 'react';
import './CustomSpinner.less';

export const CustomSpinner = () => {
  return (
    <div className="spin-container">
      <div className="custom-spinner">
        <div className="circle"></div>
        <div className="image"></div>
      </div>
    </div>
  );
};