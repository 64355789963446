
import { Layout } from 'antd';
import FooterFifthRow from './FooterElements/FooterFifthRow';
import FooterFirstRow from './FooterElements/FooterFirstRow';
import FooterForthRow from './FooterElements/FooterForthRow';
import FooterSecondRow from './FooterElements/FooterSecondRow';
import FooterThirdRow from './FooterElements/FooterThirdRow';
import './GeneralFooter.less';

const { Footer } = Layout;

const GeneralFooter = () => {
    return (
        <Footer className="site-layout-footer">
            <div className='footer-container'>
                <FooterFirstRow />
                <FooterSecondRow />
                <FooterThirdRow />
                <FooterForthRow />
                <FooterFifthRow />
            </div>
        </Footer>
    )
}

export default GeneralFooter;